exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-docs-guide-index-tsx": () => import("./../../../src/pages/docs/guide/index.tsx" /* webpackChunkName: "component---src-pages-docs-guide-index-tsx" */),
  "component---src-pages-docs-price-index-tsx": () => import("./../../../src/pages/docs/price/index.tsx" /* webpackChunkName: "component---src-pages-docs-price-index-tsx" */),
  "component---src-pages-docs-service-index-tsx": () => import("./../../../src/pages/docs/service/index.tsx" /* webpackChunkName: "component---src-pages-docs-service-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-content-page-content-page-tsx": () => import("./../../../src/templates/content-page/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-content-page-tsx" */),
  "component---src-templates-redoc-page-redoc-page-tsx": () => import("./../../../src/templates/redoc-page/redoc-page.tsx" /* webpackChunkName: "component---src-templates-redoc-page-redoc-page-tsx" */)
}

